<template>
  <div>
    <!-- HEADING TEXT -->
    <div v-if="isMobile">
      <div class="primary--text text-center text-h6 font-weight-bold">
        {{ pageTitle }}
      </div>
      <div v-if="!hasOneOffProduct" class="text-center text-caption">{{ stringForKey("choose") }}</div>
    </div>
    <div id="upgrade_text"  v-else class="primary--text text-h7 font-weight-bold">
      {{ pageTitle }}
    </div>
    <!-- BUTTONS -->
    <div
      class="grey-fill rounded-lg d-flex"
      :class="isMobile ? 'mt-4' : 'mt-8'"
    >
      <div v-if="this.hasMonthlyProduct" class="pa-2" style="width: 50%">
        <v-btn
          elevation="0"
          :color="!annual ? '#0D002D' : 'rgba(0,0,0,0)'"
          class="text-caption"
          :class="!annual ? 'white--text' : 'black--text'"
          block
          @click="switchMonthly"
          >{{ stringForKey("monthly") }}</v-btn
        >
      </div>
      <div v-if="this.hasAnnualProduct" class="pa-2" style="width: 50%">
        <v-btn
          elevation="0"
          class="text-caption"
          :color="annual ? '#0D002D' : 'rgba(0,0,0,0)'"
          :class="annual ? 'white--text' : 'black--text'"
          block
          @click="switchAnnual"
          >{{ stringForKey("annual") }}</v-btn
        >
      </div>
    </div>
    <!-- MOBILE VIEW -->
    <div v-if="isMobile">
      <div class="mt-4 pa-4 rounded-lg mobile-subs-box">
        <div class="text--primary text-center text-body1">
          {{ !annual ? monthlySubscriptiontitle : annualSubscriptionTitle }}
        </div>
        <div v-if="freeTrial" class="font-weight-bold text-h6 text-center">
          {{ freeTrial }} {{ stringForKey("subscriptionFreeTrial") }}
        </div>
         <div v-if="hasOneOffProduct" class="font-weight-bold text-h6 text-center">
          {{ oneOffPriceInterval }}
        </div>
        <div
          class="text-center font-weight-medium flex-grow-1"
          v-if="discountPercent"
        >
          {{ `${discountPercent}% ${stringForKey("subscriptionFreeTrial")}` }}
        </div>
        <div
          class="mt-2 text-center secondaryGrey--text font-weight-medium"
          style="font-size: 12px"
        >
          <div style="height: 25px" v-if="freeTrial">
            {{ stringForKey("then") }}
            <span class="font-weight-bold text-subtitle-2"
              >{{ amount }}. {{ stringForKey("cancelAnytime") }}</span
            >
          </div>
          <div v-else>
            <span class="font-weight-bold text-subtitle-1 primary--text">{{
              amount
            }}</span>
          </div>
        </div>
        <div
          class="mt-4 secondaryGrey--text font-weight-medium text-center"
          style="font-size: 12px"
        >
          <div v-if="freeTrial">
            {{ stringForKey("afterTrial") }}
            <span class="font-weight-bold text-subtitle-1">{{ amount }}</span>
          </div>
          <div v-if="freeTrial">
            {{ stringForKey("reminder") }}
          </div>
          <div v-if="!hasOneOffProduct">{{ stringForKey("noCommitment") }}</div>
        </div>
      </div>
    </div>
    <!-- DESKTOP VIEW -->
    <div v-else>
      <v-card class="mt-4 pa-8" elevation="2">
        <div class="text--primary font-weight-bold text-h5">
          {{ !annual ? monthlySubscriptiontitle : annualSubscriptionTitle }}
        </div>
        <div v-if="freeTrial" class="font-weight-medium text-h6">
          {{ freeTrial }} {{ stringForKey("subscriptionFreeTrial") }}
        </div>
        <div v-if="hasOneOffProduct" class="font-weight-medium text-h6">
          {{ oneOffPriceInterval }}
        </div>
        <div class="font-weight-medium flex-grow-1" v-if="discountPercent">
          {{ `${discountPercent}% ${stringForKey("discount")}` }}
        </div>
        <div class="divider grey-fill my-3"></div>
        <div
          class="secondaryGrey--text font-weight-medium"
          style="font-size: 14px"
        >
          <div style="height: 25px" v-if="freeTrial">
            {{ stringForKey("afterTrial") }}
            <span class="font-weight-bold text-subtitle-1 primary--text">{{
              amount
            }}</span>
          </div>
          <div v-else>
            <span class="font-weight-bold text-subtitle-1 primary--text">{{
              amount
            }}</span>
          </div>
          <div v-if="freeTrial">
            {{ stringForKey("reminder") }}
          </div>
          <div v-if="!hasOneOffProduct">{{ stringForKey("noCommitment") }}</div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import subscribe from "../subscribe";
import { mapGetters } from "vuex";
import { getStringForKey } from "@/translations/utilities";

export default {
  mixins: [subscribe],
  computed: {
    ...mapGetters({
      selectedLocale: "account/getSelectedLocale",
    }),
  },
  methods: {
    stringForKey(key) {
      return getStringForKey(this.selectedLocale, key);
    },
    switchMonthly() {
      if (this.annual && this.hasMonthlyProduct) {
        this.upgrade();
      }
    },
    switchAnnual() {
      if (!this.annual && this.hasAnnualProduct) {
        this.upgrade();
      }
    },
  },
};
</script>

<style scoped>
.mobile-subs-box {
  border-color: rgba(112, 104, 130, 0.6);
  border-width: 2px;
  border-style: solid;
}
</style>

