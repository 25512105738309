<template>
  <div>
    <v-row style="height: 100vh">
      <v-col>
        <div style="height: 120px"></div>
        <div class="px-8">
          <sub-selector></sub-selector>
        </div>
        <div class="px-8 mt-4">
          <credit-card></credit-card>
        </div>
        <div class="my-16"></div>
        <div class="my-16"></div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import subscribe from "../subscribe";
import creditCard from "./credit-card.vue";
import subSelector from "./subscription-selector.vue";

export default {
  mixins: [subscribe],
  components: {
    subSelector,
    creditCard,
  },
};
</script>