<template>
  <div class="fill-height">
    <div v-if="isDesktop">
      <desktop-view></desktop-view>
    </div>
    <div v-else>
      <mobile-view></mobile-view>
    </div>
  </div>
</template>

<script>
import mobileView from "./components/mobile-view";
import desktopView from "./components/desktop-view";

export default {
  components: {
    mobileView,
    desktopView,
  },
  computed: {
    isDesktop() {
      return this.$vuetify.breakpoint.mdAndUp;
    },
  },
};
</script>

<style>
#card-error {
  color: red;
  padding-top: 10px;
}

.unselected-button {
  color: #bcbcbccc !important;
  border: 1px solid #bcbcbc62;
  border-radius: 5px;
  padding: 6px;
  text-align: center;
}

.selected-button {
  color: white !important;
  border: 1px solid var(--v-secondary-base);
  border-radius: 5px;
  padding: 6px;
  text-align: center;
}
</style>