<template>
  <div>
    <v-row no-gutters align="center" style="height: 100vh">
      <v-col cols="12">
        <progress-indicator
          :steps="steps"
          :currentStep="currentStep"
        ></progress-indicator>
        <v-row class="mt-16" align="center">
          <v-col cols="6" class="justify-center px-8">
            <div class="px-8" style="margin-left: auto; max-width: 550px">
              <sub-selector></sub-selector>
            </div>
          </v-col>
          <v-col cols="6" class="justify-center px-16" style="max-width: 550px">
            <credit-card></credit-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import subSelector from "./subscription-selector.vue";
import subscribe from "../subscribe";
import progressIndicator from "@/components/progress-indicator";
import creditCard from "./credit-card";

export default {
  components: {
    subSelector,
    progressIndicator,
    creditCard,
  },
  mixins: [subscribe],
  computed: {
    steps() {
      return this.deal != null ? 4 : 3;
    },
    currentStep() {
      return this.deal != null ? 3 : 2;
    },
  },
};
</script>